import React from 'react';
import $ from 'jquery';
import './App.css';

import { initializeApp } from 'firebase/app';
import * as firebaseDB from "firebase/database";

// Set the configuration for your app
// TODO: Replace with your project's config object
const firebaseConfig = {
  apiKey: "AIzaSyCUJJOb0D7WKL6ux80owc4BDdZEBsFICJ4",
  authDomain: "test-project-4c72a.firebaseapp.com",
  databaseURL: "https://test-project-4c72a.firebaseio.com",
  projectId: "test-project-4c72a",
  storageBucket: "test-project-4c72a.appspot.com",
  messagingSenderId: "971899132380",
  appId: "1:971899132380:web:dad713dc18c55c1d49d719"
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const db = firebaseDB.getDatabase(app);
const dbItemsRef = firebaseDB.ref(db, 'items');

const randomColor = function() {
  const o = Math.round, r = Math.random, s = 255;

  const randomBgColor = 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',0.3)';
  document.body.style.backgroundColor = randomBgColor;

  const randomFontColor = 'rgba(' + o(r()*(s/2)) + ',' + o(r()*(s/2)) + ',' + o(r()*(s/2)) + ',1)';
  document.body.style.color = randomFontColor;
}

// GIF Component
class Giphy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gif_tag: 'dogs',
      gif_url: '',
    }

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getGIF = this.getGIF.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClick(e) {
    $("#gif").toggleClass('active');
    if ( $("#gif").hasClass('active') ) {
      setTimeout(function() {
        $("#gif input[type='text']").focus();
      }, 300);

    }
  }

  handleChange(e) {
    const target = e.target;
    this.setState({gif_tag: target.value});
  }

  getGIF() {
    const giphy_api = 'gqHQubTws7lXVSN1DRqPdqjxuQSuy4qI';
    const giphy_url = 'https://api.giphy.com/v1/gifs/random?api_key='+giphy_api+'&tag='+this.state.gif_tag+'&limit=1&rating=pg';
    const comp = this;

    $.ajax({
      url: giphy_url,
      method: 'GET',
    }).done(function(data) {
      // console.log(data.data);
      var gif_url = data.data.images.downsized_large.url;

      comp.setState({gif_url: gif_url});
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.getGIF();
    this.handleClick();
    randomColor();
  }

  componentDidMount() {
    this.getGIF();
  }

  render() {
    return (
      <div id="gif">
        <img src={this.state.gif_url} onClick={this.handleClick} />
        <form id="form-gif" onSubmit={this.handleSubmit}>
          <label>
            <input required id="gif_tag" name="gif_tag" type="text" placeholder="Search Gifs" value={this.state.gif_tag} onChange={this.handleChange} autoComplete="off" tabIndex="-1" />
            <input type="submit" value="Submit" tabIndex="-1" />
          </label>
        </form>
      </div>
    );
  }
}

// Form Component
class AddForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const target = e.target;
    this.setState({item: target.value});
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.addItem(this.state.item);
    this.setState({item: '',});
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <label>
          <input required id="item" name="item" type="text" placeholder="ADD ITEM" value={this.state.item} onChange={this.handleChange} autoComplete="off" />
          <input type="submit" value="Submit" />
        </label>
      </form>
    );
  }
}

// List Component
class GroceryList extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.items != null) {
      return (
        <ul>
          {Object.entries(this.props.items).map(([key, value]) => <ListItem key={key} itemID={key} value={value.itemName} removeItem={this.props.removeItem} />)}
        </ul>
      );
    } else {
      return (
        <p>No Items</p>
      );
    }
  }

}

// List Item Component
class ListItem extends React.Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    this.props.removeItem(this.props.itemID);
  }

  componentDidMount() {
    randomColor();
  }

  componentWillUnmount() {
    randomColor();
  }

  render() {
    return (
      <li><span className="remove-item" onClick={this.handleClick}>X</span><span>{this.props.value}</span></li>
    );
  }

}



// Parent Component
class App extends React.Component {
  constructor(props) {
    super(props);

    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);

    this.state = {groceryItems: []};
  }

  componentDidMount() {
    const items = firebaseDB.onValue(dbItemsRef, (snapshot) => {
      const data = snapshot.val();
      this.setState({groceryItems: data});
    });

  }

  addItem(item) {
    const newItemRef = firebaseDB.push(dbItemsRef);
    firebaseDB.set(newItemRef, {
      itemName: item
    });
  }

  removeItem(itemID) {
    firebaseDB.remove(firebaseDB.ref(db, 'items/'+itemID));
  }

  render() {
    return (
      <div className="App">

        <Giphy />
        <AddForm addItem={this.addItem} />
        <GroceryList items={this.state.groceryItems} removeItem={this.removeItem} />

      </div>
    );
  }

}

export default App;
